.login-form {
  width: 300px;
}

.login-form-forgot {
  float: right;
}

.login-form-button {
  width: 100%;
}
