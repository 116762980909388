.font-neutral * {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}*,
*:before,
*:after {
  margin: 0;
	padding: 0;
  box-sizing: border-box;
  font-smoothing: antialiased;
	-webkit-font-smoothing: antialiased;
	text-rendering: optimizeLegibility;
}
.header {
	padding-top: 40px;
}
.logo {
  font-size: calc(120% + 1vw);
  line-height: 44px;
  font-weight: bold;
}
.nav-link {
  font-size: 19px;
  line-height: 44px;
  text-decoration: none;
  color: inherit;
  display: inline-block;
  margin: 0 15px;
}
.logo img {
  height: 44px;
  display: inherit;
}
/*Text*/
.heading {
  font-size: 42px;
  line-height: 1.5;
}
.heading-sml {
  font-size: 36px;
  line-height: 1.38;
	font-weight: bold;
}
.heading-lrg {
  font-size: 56px;
  line-height: 1.38
}
.subheading {
	font-size: 22px;
	line-height: 1.8;
	font-weight: 400;
}
.paragraph {
  font-size: 19px;
  line-height: 1.8;
  font-weight: normal;
}
a {
  color: inherit;
  text-decoration: none;
}
.span {
	font-size: 15px;
	line-height: 1.5;
}
.bold {
  font-size: 22px;
  line-height: 1.5;
}
.text-center {
  text-align: center;
}
img {
	max-width: 100%;
}
/*grid*/
.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12 {
  padding: 0 20px;
}
.col-1 { flex-basis: 8.333333%; max-width: 8.333333%;}
.col-2 { flex-basis: 16.666667%; max-width: 16.666667%;}
.col-3 { flex-basis: 25%; max-width: 25%;}
.col-4 { flex-basis: 33.333333%; max-width: 33.333333%;}
.col-5 { flex-basis: 41.666667%; max-width: 41.666667%;}
.col-6 { flex-basis: 50%; max-width: 50%;}
.col-7 { flex-basis: 58.333333%; max-width: 58.333333%;}
.col-8 { flex-basis: 66.666667%; max-width: 66.666667%;}
.col-9 { flex-basis: 75%; max-width: 75%;}
.col-10 { flex-basis: 83.333333%; max-width: 83.333333%;}
.col-11 { flex-basis: 91.666667%; max-width: 91.666667%;}
.col-12 { flex-basis: 100%; max-width: 100%;}

/*flexbox styles*/
.flex {
  display: flex;
}
.flex-column {
  flex-direction: column;
}
.spread {
  justify-content: space-between;
}
.center-horizontal {
  align-items: center;
}
.center-vertical {
  justify-content: center;
}
.start {
  justify-content: flex-start;
}
.end {
  justify-content: flex-end;
}
.noshrink {
  flex-shrink: 0;
}
.wrap {
  flex-flow: wrap;
}
/*containers*/
.container-lrg,
.container,
.container-sml {
  margin: auto;
  position: relative;
  width: 100%;
}
.container-lrg {
  max-width: 1020px;
}
.container {
  max-width: 780px;
}
.container-sml {
	max-width: 470px;
}
.section {
  padding: 100px 0;
  overflow: hidden;
  position: relative;
}

/*button*/
.button {
	display: inline-block;
	padding: 15px 20px;
  border-radius: 3px;
  font-size: 15px;
  box-shadow: 0 4px 6px rgba(50,50,93,.11), 0 1px 3px rgba(0,0,0,.08);
  text-decoration: none;
  background: white;
	color: black;
  white-space: nowrap;
	border: none;
	-webkit-appearance: none;
	cursor: pointer;
}
.button__full {
	width: 100%;
	margin: 0;
	text-align: center;
}
.no-max-width {
  max-width: initial !important;
}
.white-color,
.button.accent-bg span {
  color: #fff !important;
}
.button svg,
.button span {
	display: inline-block;
	vertical-align: middle;
}
.button svg {
	height: 18px;
	max-width: 21px;
	margin-right: 5px;
}
.mask {
	overflow: hidden;
	position: relative;
  background: white;
}
.mailchimp {
	border: 15px solid white;
	border-radius: 3px;
	position: relative;
	background: white;
	z-index: 2;
	box-shadow: 0 4px 6px rgba(50,50,93,.11), 0 1px 3px rgba(0,0,0,.08);
	margin: auto;
}
.mailchimp-input {
  font-size: 15px;
  outline: none;
  border: none;
  width: 100%;
  padding: 15px;
}
.fill-white {
  fill: white;
}

.user-image {
  width: 64px;
  height: 64px;
  border-radius: 50%;
	display: inline-block;
	background-position: center;
	background-size: cover;
}
/*Team and footer social icons*/
.socialicons {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: inline-block;
  position: relative;
}
.socialicons:not(:last-of-type) {
  margin-right: 10px
}
/*padding & margins*/
.mb10:not(:last-of-type), .mb10:first-of-type { margin-bottom: 10px; }
.mb20:not(:last-of-type), .mb20:first-of-type { margin-bottom: 20px; }
.mb35:not(:last-of-type), .mb35:first-of-type { margin-bottom: 35px; }
.mb40:not(:last-of-type), .mb40:first-of-type { margin-bottom: 40px; }
.mb50:not(:last-of-type), .mb50:first-of-type { margin-bottom: 50px; }
.mb75:not(:last-of-type), .mb75:first-of-type { margin-bottom: 75px; }
.m0 { margin: 0 }
.mt10 { margin-top: 10px; }
.mt20 { margin-top: 20px; }
.mt30 { margin-top: 30px; }
.mt40 { margin-top: 40px; }
.mt50 { margin-top: 50px; }
.mt75 { margin-top: 75px; }
.mr0 { margin-right: 0px; }
.mr10 { margin-right: 10px; }
.mr20 { margin-right: 20px; }
.pl0 { padding-left:0; }
.pr0 { padding-right: 0; }
.pad10 { padding: 10px; }
.pad20 { padding: 20px; }
.pad30 { padding: 30px; }

@media screen and (max-width: 820px) {
	.container-lrg.flex,
	.container.flex,
	.container-sml.flex,
	.mobile-flex-wrap {
		flex-flow: wrap;
	}
	.col-1,
	.col-2,
	.col-3,
	.col-4,
	.col-5,
	.col-6,
	.col-7,
	.col-8,
	.col-9,
	.col-10,
	.col-11 {
		flex-basis: 100%;
		max-width: 100%;
		margin-bottom: 20px;
	}
	.mobile-col-6 {
		flex-basis: 50%;
		max-width: 50%;
	}
	.heading-sml {
		font-size: calc(110% + 2vw);
	}
	.heading {
		font-size: calc(107.5% + 3.7vw);
	}
	.heading-lrg {
		font-size: calc(105% + 5.3vw);
	}
	.section {
		padding: 60px 0;
	}
	.mobile-text-center {
		text-align: center;
	}
	.mobile-center-icon {
		margin: 0 auto 20px;
	}
	.card > div:not(:first-of-type) {
		border-top: 1px solid rgba(0, 0, 0, .1);
		border-left: none;
	}
	.mailchimp .button {
		width: 100%;
	}
}
@media screen and (max-width: 460px) {
	.button {
		max-width: 280px;
		width: 100%;
		margin-right: 0 !important;
	}
}
.logo__white {
  color: white !important;
}
.logo__black {
  color: black !important;
}
.button__black,
.button__black.accent-bg span {
  color: black !important;
}
.button__black svg {
  fill: black !important;
}
.button__white,
.button__white.accent-bg span {
  color: white !important;
}
.button__white {
  fill: white !important;
}
.press-logo {
  max-width: 100%;
  max-height: 60px;
  display: block;
  margin: auto;
}.icon {
  width: 64px;
	height: 64px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
	background: rgba(34, 126, 247, .07);
	margin-bottom: 20px;
}
.icon svg {
  width: 100%;
  max-width: 30px;
  max-height: 30px;
}.checklist {
  list-style: none;
}
.checklist-item:not(:last-of-type) {
  border-bottom: 1px solid rgba(0,0,0,.1)
}.faq:not(:last-of-type) {
	border-bottom: 1px solid rgba(0,0,0,.1)
}
.faq-input {
  position: absolute;
  opacity: 0;
  pointer-events: none;
}
.faq-label {
  cursor: pointer;
}
.faq-content {
  display: none;
}
.faq-input:checked ~ label svg {
	transform: rotate(180deg)
}
.faq-input:checked ~ .faq-content {
  display: block;
  border-bottom: 0;
}.linebreak {
  word-spacing: 300px;
}
.team-card {
  padding: 30px;
  min-height: 460px;
  border: 1px solid rgba(0,0,0,.1);
  border-radius: 3px;
}@media screen and (max-width: 820px) {
	.header-email {
		width: 30px;
		height: 30px;
		border-radius: 50%;
		overflow: hidden;
		position: relative;
		text-indent: 100px;
		margin: 6px 0;
	}
}
@media screen and (min-width: 820px) {
	.header-email {
		position: relative;
		overflow: hidden;
		background: transparent !important;
  }

  .header-email:after  {
    background: transparent !important;
  }
}.bg {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.bg-image {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  mix-blend-mode: multiply;
  filter: grayscale(100%) contrast(1);
}
.bg-author {
  position: absolute;
  bottom: 10px;
  left: 10px;
  cursor: pointer;
  z-index: 2;
}
.background,
.background-white {
	position: relative;
}
.background-white .bg-image {
	filter: initial;
}
.background-white .secondary-color,
.background-white .primary-color {
	color: white;
	text-shadow: 0 2px 0 rgba(0,0,0,.1)
}.browser {
  border-radius: 6px;
  background-size: 60px;
  overflow: hidden;
  width: 100%;
  z-index: 2;
  background-size: auto 30px;
  box-shadow: 0 20px 30px 0 rgba(0,0,0,.1);
  position: relative;
}
.browser .mask {
  max-height: 640px;
}
.browser:before {
  content: "";
  height: 30px;
  line-height: 30px;
  display: block;
  width: 100%;
  position: relative;
  background: linear-gradient(-180deg, #fafbfc 0%, #f1f4f7 100%);
}
.browser:after {
  content: "";
  width: 12px;
  height: 12px;
  background: #e2e5e5;
  position: absolute;
  border-radius: 50%;
  top: 10px;
  left: 8px;
  box-shadow: 18px 0 0 #e2e5e5, 36px 0 0 #e2e5e5;
}